import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
const token = "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";

const autocompleteMockData = {
  products: [
    {
      handle: "accessorio-da-gancio-traino",
      variants: [
        {
          product_id: 7914039509130,
          id: 44342561177738,
          title: "Default Title",
          price: "180.00",
          sku: "ABC-12345-S-BL",
          position: 2,
          inventory_policy: "deny",
          compare_at_price: "250.00",
          fulfillment_service: "manual",
          inventory_management: "shopify",
          option1: "Default Title",
          option2: null,
          option3: null,
          created_at: "2024-02-28T11:43:29-05:00",
          updated_at: "2024-03-11T13:09:44-04:00",
          taxable: true,
          barcode: "",
          grams: 0,
          weight: 0.0,
          weight_unit: "kg",
          inventory_item_id: 45737040838794,
          inventory_quantity: 10,
          old_inventory_quantity: 10,
          requires_shipping: true,
          admin_graphql_api_id: "gid://shopify/ProductVariant/44342561177738",
          image_id: null,
        },
      ],
    },
    {
      handle: "accessorio-portasci-2-piano-di-carico",
      variants: [
        {
          product_id: 7914056646794,
          id: 44342624125066,
          title: "Default Title",
          price: "250.00",
          sku: "",
          position: 2,
          inventory_policy: "deny",
          compare_at_price: "500.00",
          fulfillment_service: "manual",
          inventory_management: "shopify",
          option1: "Default Title",
          option2: null,
          option3: null,
          created_at: "2024-02-28T11:51:06-05:00",
          updated_at: "2024-02-28T11:51:06-05:00",
          taxable: true,
          barcode: "",
          grams: 0,
          weight: 0.0,
          weight_unit: "kg",
          inventory_item_id: 45737103786122,
          inventory_quantity: 10,
          old_inventory_quantity: 10,
          requires_shipping: true,
          admin_graphql_api_id: "gid://shopify/ProductVariant/44342624125066",
          image_id: null,
        },
      ],
    },
    {
      handle: "barre-da-tetto-universali",
      variants: [
        {
          product_id: 7902102847626,
          id: 44291895722122,
          title: "Default Title",
          price: "110.00",
          sku: "",
          position: 2,
          inventory_policy: "deny",
          compare_at_price: "150.00",
          fulfillment_service: "manual",
          inventory_management: "shopify",
          option1: "Default Title",
          option2: null,
          option3: null,
          created_at: "2024-02-13T05:10:18-05:00",
          updated_at: "2024-02-16T09:25:52-05:00",
          taxable: true,
          barcode: "",
          grams: 0,
          weight: 0.0,
          weight_unit: "kg",
          inventory_item_id: 45686148137098,
          inventory_quantity: 20,
          old_inventory_quantity: 20,
          requires_shipping: true,
          admin_graphql_api_id: "gid://shopify/ProductVariant/44291895722122",
          image_id: null,
        },
      ],
    },
    {
      handle: "box-da-gancio-traino",
      variants: [
        {
          product_id: 7914034692234,
          id: 44342544072842,
          title: "Default Title",
          price: "110.00",
          sku: "",
          position: 2,
          inventory_policy: "deny",
          compare_at_price: "150.00",
          fulfillment_service: "manual",
          inventory_management: "shopify",
          option1: "Default Title",
          option2: null,
          option3: null,
          created_at: "2024-02-28T11:40:58-05:00",
          updated_at: "2024-02-28T11:40:58-05:00",
          taxable: true,
          barcode: "",
          grams: 0,
          weight: 0.0,
          weight_unit: "kg",
          inventory_item_id: 45737023733898,
          inventory_quantity: 10,
          old_inventory_quantity: 10,
          requires_shipping: true,
          admin_graphql_api_id: "gid://shopify/ProductVariant/44342544072842",
          image_id: null,
        },
      ],
    },
    {
      handle: "box-da-tetto",
      variants: [
        {
          product_id: 7902102716554,
          id: 44291894280330,
          title: "Default Title",
          price: "80.00",
          sku: "22.GP108",
          position: 2,
          inventory_policy: "deny",
          compare_at_price: "100.00",
          fulfillment_service: "manual",
          inventory_management: "shopify",
          option1: "Default Title",
          option2: null,
          option3: null,
          created_at: "2024-02-13T05:08:12-05:00",
          updated_at: "2024-02-21T03:10:55-05:00",
          taxable: true,
          barcode: "",
          grams: 0,
          weight: 0.0,
          weight_unit: "kg",
          inventory_item_id: 45686146695306,
          inventory_quantity: 19,
          old_inventory_quantity: 19,
          requires_shipping: true,
          admin_graphql_api_id: "gid://shopify/ProductVariant/44291894280330",
          image_id: null,
        },
      ],
    },
    {
      handle: "buono-regalo-gp",
      variants: [
        {
          product_id: 7917344293002,
          id: 44357998739594,
          title: "50",
          price: "50.00",
          sku: "",
          position: 1,
          inventory_policy: "deny",
          compare_at_price: null,
          fulfillment_service: "gift_card",
          inventory_management: null,
          option1: "50",
          option2: null,
          option3: null,
          created_at: "2024-03-01T06:08:46-05:00",
          updated_at: "2024-03-01T06:09:22-05:00",
          taxable: false,
          barcode: null,
          grams: 0,
          weight: 0.0,
          weight_unit: "kg",
          inventory_item_id: 45752484593802,
          inventory_quantity: 0,
          old_inventory_quantity: 0,
          requires_shipping: false,
          admin_graphql_api_id: "gid://shopify/ProductVariant/44357998739594",
          image_id: null,
        },
        {
          product_id: 7917344293002,
          id: 44357998772362,
          title: "100",
          price: "100.00",
          sku: "",
          position: 2,
          inventory_policy: "deny",
          compare_at_price: null,
          fulfillment_service: "gift_card",
          inventory_management: null,
          option1: "100",
          option2: null,
          option3: null,
          created_at: "2024-03-01T06:08:46-05:00",
          updated_at: "2024-03-01T06:09:22-05:00",
          taxable: false,
          barcode: null,
          grams: 0,
          weight: 0.0,
          weight_unit: "kg",
          inventory_item_id: 45752484626570,
          inventory_quantity: 0,
          old_inventory_quantity: 0,
          requires_shipping: false,
          admin_graphql_api_id: "gid://shopify/ProductVariant/44357998772362",
          image_id: null,
        },
        {
          product_id: 7917344293002,
          id: 44357998805130,
          title: "200",
          price: "200.00",
          sku: "",
          position: 3,
          inventory_policy: "deny",
          compare_at_price: null,
          fulfillment_service: "gift_card",
          inventory_management: null,
          option1: "200",
          option2: null,
          option3: null,
          created_at: "2024-03-01T06:08:46-05:00",
          updated_at: "2024-03-01T06:09:22-05:00",
          taxable: false,
          barcode: null,
          grams: 0,
          weight: 0.0,
          weight_unit: "kg",
          inventory_item_id: 45752484659338,
          inventory_quantity: 0,
          old_inventory_quantity: 0,
          requires_shipping: false,
          admin_graphql_api_id: "gid://shopify/ProductVariant/44357998805130",
          image_id: null,
        },
      ],
    },
    {
      handle: "portabici-gancio-traino-per-2-e-bikes",
      variants: [
        {
          product_id: 7914054287498,
          id: 44342616424586,
          title: "Default Title",
          price: "190.00",
          sku: "",
          position: 2,
          inventory_policy: "deny",
          compare_at_price: "400.00",
          fulfillment_service: "manual",
          inventory_management: "shopify",
          option1: "Default Title",
          option2: null,
          option3: null,
          created_at: "2024-02-28T11:50:00-05:00",
          updated_at: "2024-02-28T11:51:40-05:00",
          taxable: true,
          barcode: "",
          grams: 0,
          weight: 0.0,
          weight_unit: "kg",
          inventory_item_id: 45737096085642,
          inventory_quantity: 20,
          old_inventory_quantity: 20,
          requires_shipping: true,
          admin_graphql_api_id: "gid://shopify/ProductVariant/44342616424586",
          image_id: null,
        },
      ],
    },
    {
      handle: "portabici-posteriore-per-2-e-bikes",
      variants: [
        {
          product_id: 7914037411978,
          id: 44342553804938,
          title: "Default Title",
          price: "160.00",
          sku: "",
          position: 2,
          inventory_policy: "deny",
          compare_at_price: "200.00",
          fulfillment_service: "manual",
          inventory_management: "shopify",
          option1: "Default Title",
          option2: null,
          option3: null,
          created_at: "2024-02-28T11:42:15-05:00",
          updated_at: "2024-02-28T11:51:31-05:00",
          taxable: true,
          barcode: "",
          grams: 0,
          weight: 0.0,
          weight_unit: "kg",
          inventory_item_id: 45737033465994,
          inventory_quantity: 20,
          old_inventory_quantity: 20,
          requires_shipping: true,
          admin_graphql_api_id: "gid://shopify/ProductVariant/44342553804938",
          image_id: null,
        },
      ],
    },
    {
      handle: "portasci-da-tetto",
      variants: [
        {
          product_id: 7914041737354,
          id: 44342568878218,
          title: "Default Title",
          price: "250.00",
          sku: "",
          position: 2,
          inventory_policy: "deny",
          compare_at_price: "300.00",
          fulfillment_service: "manual",
          inventory_management: "shopify",
          option1: "Default Title",
          option2: null,
          option3: null,
          created_at: "2024-02-28T11:44:31-05:00",
          updated_at: "2024-02-28T11:51:32-05:00",
          taxable: true,
          barcode: "",
          grams: 0,
          weight: 0.0,
          weight_unit: "kg",
          inventory_item_id: 45737048539274,
          inventory_quantity: 20,
          old_inventory_quantity: 20,
          requires_shipping: true,
          admin_graphql_api_id: "gid://shopify/ProductVariant/44342568878218",
          image_id: null,
        },
      ],
    },
  ],
};
export default function SkuSearch() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [autocompleteData, setAutocompleteData] = useState();
  const [suggestions, setSuggestions] = useState([]);

  const onSubmit = (data) => {
    console.log(data);
    window.location.href = `/search?q=${data.search}`;
  };

  const autocomplete = async (q) => {
    const response = await axios
      .get(
        `https://gp.bkt.studio/shopify/products`,
        {
          headers: {
            "X-Shopify-Access-Token": token,
            "Content-Type": "application/json",
          },
        }
      )
      .catch((err) => {});
    try {
      setAutocompleteData(response.data.products);
    } catch (err) {
      setAutocompleteData(autocompleteMockData.products);
    }
  };

  const performSearch = (q) => {
    const _suggestions = [];
    autocompleteData?.filter(p => p.status === 'active').forEach((product) => {
      product.variants.forEach((variant) => {
        if (variant.sku?.toLowerCase().includes(q.toLowerCase())) {
          _suggestions.push(product);
        }
      });
    });

    setSuggestions(_suggestions);
  };

  useEffect(() => {
    const _q = watch("search");

    if (_q?.length > 2) {
      if (!autocompleteData) {
        autocomplete(_q);
      } else {
        performSearch(_q);
      }
    } else {
      setSuggestions([]);
    }
  }, [watch("search")]);

  useEffect(() => {
    if (autocompleteData) {
      performSearch(watch("search"));
    }
  }, [autocompleteData]);

  return (
    <div>
      <form
        role="search"
        method="get"
        className="searchform"
        onSubmit={handleSubmit(onSubmit)}
      >
        <fieldset>
          <input
            type="search"
            className="search-field"
            placeholder="Cerca per Codice Prodotto"
            {...register("search", { required: true })}
            autocomplete="off"
            style={{
              background: "rgba(0,0,0,.7)",
              borderRadius: 0,
            }}
          />
          {errors.search && <span>This field is required</span>}

          <button type="submit" className="thb-search-submit">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.97208 0C4.0309 0 0 4.03029 0 8.97187C0 13.9135 4.03038 17.9437 8.97208 17.9437C11.0258 17.9437 12.9198 17.249 14.4348 16.0808L18.0149 19.6608C18.4672 20.1131 19.209 20.1131 19.6608 19.6608C20.1131 19.2085 20.1131 18.476 19.6608 18.0238L16.0714 14.4438C17.2422 12.9282 17.9436 11.0281 17.9436 8.97187C17.9436 4.0308 13.9138 0 8.97208 0ZM8.97208 2.3152C12.6618 2.3152 15.6284 5.2817 15.6284 8.97135C15.6284 12.661 12.6618 15.6275 8.97208 15.6275C5.28235 15.6275 2.31578 12.661 2.31578 8.97135C2.31578 5.2817 5.28235 2.3152 8.97208 2.3152Z"
                fill="#fff"
              ></path>
            </svg>
          </button>
        </fieldset>
      </form>
      {suggestions.length > 0 && (
        <div
          style={{
            background: "rgba(0,0,0,.7)",
       
          }}
        >
          {suggestions.map((product) => {
            return product.variants.map((variant) => {
              if (!variant.sku) {
                return null;
              }
              return (
                <button
                  onClick={() => {
                    window.location.href = `/products/${product.handle}?variant=${variant.id}`;
                  }}
                  key={variant.id}
                  style={{
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      padding: "5px 10px",
                      borderBottom: "none",
                      width: "100%",
                      justifyContent: "space-around",
                    }}
                  >
                    {product?.image && (
                      <img
                        src={product.image.src}
                        alt={product.title}
                        style={{ width: "100px", marginRight: "10px" }}
                      />
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <p style={{marginBottom:0}}>{product.title}</p>
                      <p style={{marginBottom:0, opacity: 0.8}}>
                        <small>{variant.sku}</small>
                      </p>
                    </div>
                    <div>
                        <p style={{marginBottom:0, textAlign: "right"}}>€{variant.price}</p>
                    </div>
                  </div>
                </button>
              );
            });
          })}
        </div>
      )}
    </div>
  );
}
