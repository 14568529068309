import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import SkuSearch from "./SkuSearch";
import Name from "./Name";
import Advanced from "./Advanced";

export default function App() {
  const [searchType, setSearchType] = useState("advanced");
  const [locale, setLocale] = useState("it");

  useEffect(() => {
    const _loc = window?.Shopify?.locale;
    if (_loc) {
      setLocale(_loc);
    }
  }, []);

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "rgb(0, 0, 0, .8)",
      }}
    >
      <p>{locale === "it" ? "Cerca prodotto" : "Search product"}</p>
      <scroll-shadow>
        <collection-tabs className="collection-tabs__list">
          <button
            type="button"
            className={
              "collection-tabs__list-link" +
              (searchType === "advanced" ? " active" : "")
            }
            onClick={() => setSearchType("advanced")}
          >
            {locale === "it" ? "per veicolo" : "by vehicle"}
          </button>
          <button
            type="button"
            className={
              "collection-tabs__list-link" +
              (searchType === "sku" ? " active" : "")
            }
            onClick={() => setSearchType("sku")}
          >
            {locale === "it" ? "per codice" : "by code"}
          </button>
          <button
            type="button"
            className={
              "collection-tabs__list-link" +
              (searchType === "name" ? " active" : "")
            }
            onClick={() => setSearchType("name")}
          >
            {locale === "it" ? "per nome" : "by name"}
          </button>
        </collection-tabs>
      </scroll-shadow>
      <div
        style={{
          marginTop: "20px",
        }}
      >
        {searchType === "sku" && <SkuSearch />}
        {searchType === "name" && <Name />}
        {searchType === "advanced" && <Advanced />}
      </div>
    </div>
  );
}
