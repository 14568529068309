import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
const token = "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";

const autocompleteMockData = {
  products: [
    // Mock data invariato
  ],
};

export default function Name() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [autocompleteData, setAutocompleteData] = useState();
  const [suggestions, setSuggestions] = useState([]);
  const [locale, setLocale] = useState("it");

  useEffect(() => {
    const _loc = window?.Shopify?.locale;
    if (_loc) {
      setLocale(_loc);
    }
  }, []);

  const onSubmit = (data) => {
    console.log(data);
    if (locale === "it") {
    window.location.href = `/search?q=${data.search}`;
    }else{
      window.location.href = `/en/search?q=${data.search}`;
    }
  };

  const autocomplete = async (q) => {
    const response = await axios
      .get(`https://gp.bkt.studio/shopify/products`, {
        headers: {
          "X-Shopify-Access-Token": token,
          "Content-Type": "application/json",
        },
      })
      .catch((err) => {});
    try {
      setAutocompleteData(response.data.products);
    } catch (err) {
      setAutocompleteData(autocompleteMockData.products);
    }
  };

  const performSearch = (q) => {
    const _suggestions = [];
    autocompleteData
      ?.filter((p) => p.status === "active")
      .forEach((product) => {
        if (product.title?.toLowerCase().includes(q.toLowerCase())) {
          _suggestions.push(product);
        }
      });

    setSuggestions(_suggestions);
  };

  useEffect(() => {
    const _q = watch("search");

    if (_q?.length > 2) {
      if (!autocompleteData) {
        autocomplete(_q);
      } else {
        performSearch(_q);
      }
    } else {
      setSuggestions([]);
    }
  }, [watch("search")]);

  useEffect(() => {
    if (autocompleteData) {
      performSearch(watch("search"));
    }
  }, [autocompleteData]);

  return (
    <div>
      <form
        role="search"
        method="get"
        className="searchform"
        onSubmit={handleSubmit(onSubmit)}
      >
        <fieldset>
          <input
            type="search"
            className="search-field"
            placeholder={locale === "it" ? "Cerca per Nome" : "Search by Name"}
            {...register("search", { required: true })}
            autoComplete="off"
            style={{
              background: "rgba(0,0,0,.7)",
              borderRadius: 0,
            }}
          />
          {errors.search && (
            <span>
              {locale === "it"
                ? "Questo campo è obbligatorio"
                : "This field is required"}
            </span>
          )}

          <button type="submit" className="thb-search-submit">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.97208 0C4.0309 0 0 4.03029 0 8.97187C0 13.9135 4.03038 17.9437 8.97208 17.9437C11.0258 17.9437 12.9198 17.249 14.4348 16.0808L18.0149 19.6608C18.4672 20.1131 19.209 20.1131 19.6608 19.6608C20.1131 19.2085 20.1131 18.476 19.6608 18.0238L16.0714 14.4438C17.2422 12.9282 17.9436 11.0281 17.9436 8.97187C17.9436 4.0308 13.9138 0 8.97208 0ZM8.97208 2.3152C12.6618 2.3152 15.6284 5.2817 15.6284 8.97135C15.6284 12.661 12.6618 15.6275 8.97208 15.6275C5.28235 15.6275 2.31578 12.661 2.31578 8.97135C2.31578 5.2817 5.28235 2.3152 8.97208 2.3152Z"
                fill="#fff"
              ></path>
            </svg>
          </button>
        </fieldset>
      </form>
      {suggestions.length > 0 && (
        <div
          style={{
            background: "rgba(0,0,0,.7)",
          }}
        >
          {suggestions.map((product) => {
            return product.variants.map((variant) => {
              return (
                <button
                  onClick={() => {
                    if (locale === "it") {
                      window.location.href = `/products/${product.handle}?variant=${variant.id}`;
                    } else {
                      window.location.href = `/en/products/${product.handle}?variant=${variant.id}`;
                    }
                  }}
                  key={variant.id}
                  style={{
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      padding: "5px 10px",
                      borderBottom: "none",
                      width: "100%",
                      justifyContent: "space-around",
                    }}
                  >
                    {product?.image && (
                      <img
                        src={product.image.src}
                        alt={product.title}
                        style={{ width: "100px", marginRight: "10px" }}
                      />
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <p style={{ marginBottom: 0 }}>{product.title}</p>
                      <p style={{ marginBottom: 0, opacity: 0.8 }}>
                        <small>{variant.sku}</small>
                      </p>
                    </div>
                    <div>
                      <p style={{ marginBottom: 0, textAlign: "right" }}>
                        €{variant.price}
                      </p>
                    </div>
                  </div>
                </button>
              );
            });
          })}
        </div>
      )}
    </div>
  );
}
