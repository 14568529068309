import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import Loading from "./Loading";
const token = "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";

const BrandSelect = ({ cb, queryObject, setQueryObject }) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const retrieveData = async () => {
    setLoading(true);
    const response = await axios
      .get("https://gp.bkt.studio/brands")
      .catch((e) => console.log(e));
    setData(response.data);
    setLoading(false);
  };

  useEffect(() => {
    retrieveData();
  }, []);

  return (
    <select
      style={{
        flex: 1,
      }}
      onChange={(e) => {
        console.log("value", e.target.value);
        cb(e.target.value);
        setQueryObject({
          ...queryObject,
          brand: data?.find((brand) => brand.metaId === e.target.value).name,
        });
      }}
    >
      <option selected disabled>
        Produttore
      </option>
      {data?.map((brand) => (
        <option key={brand._id} value={brand.metaId}>
          {brand.name}
        </option>
      ))}
    </select>
  );
};

const ModelliSelect = ({ cb, dependency, queryObject, setQueryObject }) => {
  const [data, setData] = useState();

  const [loading, setLoading] = useState(false);

  const retrieveData = async () => {
    setLoading(true);
    const response = await axios
      .get("https://gp.bkt.studio/model?brandId=" + dependency)
      .catch((e) => console.log(e));
    setData(response.data);
    setLoading(false);
  };
  useEffect(() => {
    if (dependency) {
      cb(null);
      retrieveData();
    }
  }, [dependency]);
  if (loading)
    return (
      <div
        style={{
          flex: 1,
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        {" "}
        <Loading />
      </div>
    );

  return (
    <select
      disabled={!dependency}
      style={{
        flex: 1,
      }}
      onChange={(e) => {
        cb(e.target.value);
        setQueryObject({
          ...queryObject,
          modello: data?.find((modello) => modello.metaId === e.target.value)
            ?.name,
        });
      }}
    >
      <option selected disabled>
        Modello
      </option>

      {data?.map((modello) => (
        <option key={modello._id} value={modello.metaId}>
          {modello.name}
        </option>
      ))}
    </select>
  );
};

const PorteOption = ({ porte }) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const retrieveData = async () => {
    setLoading(true);
    const response = await axios
      .get("https://gp.bkt.studio/year?portsId=" + porte.metaId)
      .catch((e) => console.log(e));
    setData(response.data);
    setLoading(false);
  };

  useEffect(() => {
    retrieveData();
  }, []);

  return (
    <option key={porte._id} value={porte.metaId}>
      {porte.name}p ({data && data?.length > 0 && data[0]?.name})
    </option>
  );
};

const PorteSelect = ({ cb, dependency, queryObject, setQueryObject }) => {
  const [data, setData] = useState();

  const [loading, setLoading] = useState(false);

  const retrieveData = async () => {
    setLoading(true);
    const response = await axios
      .get("https://gp.bkt.studio/ports?modelId=" + dependency)
      .catch((e) => console.log(e));
    setData(response.data);
    setLoading(false);
  };

  useEffect(() => {
    if (dependency) {
      cb(null);
      retrieveData();
    }
  }, [dependency]);
  if (loading)
    return (
      <div
        style={{
          flex: 1,
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        {" "}
        <Loading />
      </div>
    );
  return (
    <select
      disabled={!dependency}
      style={{
        flex: 1,
      }}
      onChange={(e) => {
        cb(e.target.value);
        setQueryObject({
          ...queryObject,
          porte: data?.find((porte) => porte.metaId === e.target.value)?.name,
        });
      }}
    >
      <option selected disabled>
        Porte
      </option>
      {data?.map((porte) => (
        <PorteOption porte={porte} />
      ))}
    </select>
  );
};

const AnnoSelect = ({ cb, dependency, queryObject, setQueryObject }) => {
  const [data, setData] = useState();
  const [value, setValue] = useState();

  const [loading, setLoading] = useState(false);

  const retrieveData = async () => {
    setLoading(true);
    const response = await axios
      .get("https://gp.bkt.studio/year?portsId=" + dependency)
      .catch((e) => console.log(e));
    setData(response.data);
    setLoading(false);
  };

  useEffect(() => {
    if (data) {
      if (data.length === 1) {
        cb(data[0].metaId);
        setValue(data[0].metaId);
        setQueryObject({
          ...queryObject,
          anno: data?.find((y) => y.metaId === data[0].metaId)?.name,
        });
      }
    }
  }, [data]);

  useEffect(() => {
    if (dependency) {
      cb(null);
      retrieveData();
    }
  }, [dependency]);

  if (loading)
    return (
      <div
        style={{
          flex: 1,
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        {" "}
        <Loading />
      </div>
    );
  return (
    <select
      disabled={!dependency}
      value={value}
      style={{
        flex: 1,
      }}
      onChange={(e) => {
        cb(e.target.value);
        setValue(e.target.value);
        setQueryObject({
          ...queryObject,
          anno: data?.find((y) => y.metaId === e.target.value)?.name,
        });
      }}
    >
      <option selected disabled>
        Anno
      </option>

      {data?.map((year) => (
        <option key={year._id} value={year.metaId}>
          {year.name}
        </option>
      ))}
          
    </select>
  );
};

export default function Advanced() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    console.log('queryObject', queryObject)
    const queryString = Object.values(queryObject)
      .map((e) =>
        e
          .toLowerCase()
          .replace(" ", "")
          .replace(">", "")
          .replace("-", "")
          .replace("/", "")
      )
      .join("-");

    window.location.href = `/collections/${queryString}`;
  };

  const [brand, setBrand] = useState();
  const [modelli, setModelli] = useState();
  const [porte, setPorte] = useState();
  const [anno, setAnno] = useState();
  const [queryObject, setQueryObject] = useState({});

  return (
    <div>
      <form
        role="search"
        method="get"
        className="searchform"
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <fieldset
          className="fs-advanced-search"
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <BrandSelect
            cb={setBrand}
            queryObject={queryObject}
            setQueryObject={setQueryObject}
          />
          <ModelliSelect
            dependency={brand}
            cb={setModelli}
            queryObject={queryObject}
            setQueryObject={setQueryObject}
          />
          <PorteSelect
            dependency={modelli}
            cb={setPorte}
            queryObject={queryObject}
            setQueryObject={setQueryObject}
          />
          <AnnoSelect
            dependency={porte}
            cb={setAnno}
            queryObject={queryObject}
            setQueryObject={setQueryObject}
          />
        </fieldset>
        <input
          type="submit"
          style={{
            flex: 1,
          }}
          disabled={!anno}
          value="Cerca"
        />
      </form>
    </div>
  );
}
