import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  gql,
} from "@apollo/client";


const client = new ApolloClient({
  uri: "https://gp-storefront.myshopify.com/admin/api/2023-10/graphql.json",
  cache:new InMemoryCache(),
  fetchOptions: {
    mode: "no-cors",
  },
  request: operation => {
    operation.setContext({
      headers: {
        "X-Shopify-Access-Token": "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",
        "Content-type" : "application/json",

      }
    });
  },
  credentials:"include"

});

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "en", // language to use
  fallbackLng: ["it", "de"],
  resources: {
    en: {
      translation: {
        widget: {
          title: "Buying and Re-selling has never been easier!",
          description: `Resellify is the perfect platform to resell your luxury items. Automatically add a new product you are purchasing to your digital Wardrobe and choose to resell it whenever you want with a simple click! Easy, fast and responsible.`,
          cta: "Learn more",
          disclaimer: `You are about to share your personal data and purchases with the Resellify marketplace platform. For more details see our`,
          terms: "I agree",
        },
        modal: {
          title: "How it works",
          step1: `<p><strong>1. Add to Resellify</strong></p> We have partnered with Resellify to enable you to join the circular fashion movement. Resell your purchases on the Resellify platform with a simple click. Together, we can do our part to keep fashion out of landfills.`,
          step2: `<p><strong>2. Resell After</strong></p>
          After you have worn and loved your item, log into app.resellify.it to list your item on the Resellify marketplace with a single click.`,
          step3: `<p><strong>💡 What if I don't have a Resellify account?</strong></p>
          No worries! You will be directed to set up your free account on the order confirmation page.`,
          step4: ` You are about to share your personal data and purchases with the Resellify marketplace platform. For more details see our &nbsp;
          <a href="https://app.resellify.it/privacy-policy" target="blank">
            Privacy Policy
          </a>`,
        },
      },
    },
    de: {
      translation: {
        widget: {
          title: "Kaufen und Wiederverkaufen war noch nie so einfach!",
          description: `Resellify ist die perfekte Plattform, um Ihre Luxusartikel weiterzuverkaufen. Fügen Sie automatisch ein neues Produkt, das Sie kaufen, zu Ihrem digitalen Kleiderschrank hinzu und entscheiden Sie sich mit einem einfachen Klick, es wann immer Sie möchten weiterzuverkaufen! Einfach, schnell und verantwortungsbewusst.`,
          cta: "Erfahre mehr",
          disclaimer: `Sie sind dabei, Ihre persönlichen Daten und Einkäufe mit der Resellify-Marktplattform zu teilen. Weitere Details finden Sie in unserer`,
          terms: "Ich stimme zu",
        },
        modal: {
          title: "Wie es funktioniert",
          step1: `<p><strong>1. Zu Resellify hinzufügen</strong></p> Wir haben uns mit Resellify zusammengetan, um Ihnen die Teilnahme an der Kreislaufbewegung der Mode zu ermöglichen. Verkaufen Sie Ihre Einkäufe auf der Resellify-Plattform mit einem einfachen Klick. Gemeinsam können wir unseren Teil dazu beitragen, die Mode aus Deponien herauszuhalten.`,
          step2: `<p><strong>2. Nach dem Verkauf</strong></p>
          Nachdem Sie Ihren Artikel getragen und geliebt haben, melden Sie sich unter app.resellify.it an, um Ihren Artikel mit einem einzigen Klick auf dem Resellify-Marktplatz aufzulisten.`,
          step3: `<p><strong>💡 Was ist, wenn ich kein Resellify-Konto habe?</strong></p>
          Keine Sorge! Sie werden aufgefordert, Ihr kostenloses Konto auf der Bestellbestätigungsseite einzurichten.`,
          step4: ` Sie sind dabei, Ihre persönlichen Daten und Einkäufe mit der Resellify-Marktplattform zu teilen. Weitere Details finden Sie in unserer &nbsp;
          <a href="https://app.resellify.it/privacy-policy" target="blank">
            Datenschutz-Bestimmungen
          </a>`,
        },
      },
    },
    es: {
      translation: {
        widget: {
          title: "¡Comprar y revender nunca ha sido tan fácil!",
          description: `Resellify es la plataforma perfecta para revender sus artículos de lujo. ¡Agregue automáticamente un nuevo producto que está comprando a su guardarropa digital y elija revenderlo cuando quiera con un simple clic! ¡Fácil, rápido y responsable!`,
          cta: "Aprende más",
          disclaimer: `Está a punto de compartir sus datos personales y compras con la plataforma del mercado de Resellify. Para más detalles ver nuestro`,
          terms: "Estoy de acuerdo",
        },
        modal: {
          title: "Cómo funciona",
          step1: `<p><strong>1. Agregar a Resellify</strong></p> Nos hemos asociado con Resellify para permitirle unirse al movimiento de moda circular. Revenda sus compras en la plataforma Resellify con un simple clic. Juntos, podemos hacer nuestra parte para mantener la moda fuera de los vertederos.`,
          step2: `<p><strong>2. Revender después</strong></p>
          Después de usar y amar su artículo, inicie sesión en app.resellify.it para enumerar su artículo en el mercado de Resellify con un solo clic.`,
          step3: `<p><strong>💡 ¿Qué pasa si no tengo una cuenta de Resellify?</strong></p>
          ¡No te preocupes! Se le dirigirá a configurar su cuenta gratuita en la página de confirmación del pedido.`,
          step4: ` Está a punto de compartir sus datos personales y compras con la plataforma del mercado de Resellify. Para más detalles ver nuestro &nbsp;
          <a href="https://app.resellify.it/privacy-policy" target="blank">
            Política de privacidad
          </a>`,
        },
      },
    },
    pt: {
      translation: {
        widget: {
          title: "Comprar e revender nunca foi tão fácil!",
          description: `Resellify é a plataforma perfeita para revender seus itens de luxo. Adicione automaticamente um novo produto que você está comprando ao seu guarda-roupa digital e escolha revendê-lo sempre que quiser com um simples clique! Fácil, rápido e responsável.`,
          cta: "Saiba mais",
          disclaimer: `Você está prestes a compartilhar seus dados pessoais e compras com a plataforma do mercado Resellify. Para mais detalhes, consulte o nosso`,
          terms: "Eu concordo",
        },
        modal: {
          title: "Como funciona",
          step1: `<p><strong>1. Adicionar ao Resellify</strong></p> Nós nos associamos à Resellify para permitir que você participe do movimento de moda circular. Revenda suas compras na plataforma Resellify com um simples clique. Juntos, podemos fazer nossa parte para manter a moda fora dos aterros sanitários.`,
          step2: `<p><strong>2. Revender depois</strong></p>
          Depois de usar e amar seu item, faça login em app.resellify.it para listar seu item no mercado Resellify com um único clique.`,
          step3: `<p><strong>💡 E se eu não tiver uma conta Resellify?</strong></p>
          Sem problemas! Você será direcionado para configurar sua conta gratuita na página de confirmação do pedido.`,
          step4: ` Você está prestes a compartilhar seus dados pessoais e compras com a plataforma do mercado Resellify. Para mais detalhes, consulte o nosso &nbsp;
          <a href="https://app.resellify.it/privacy-policy" target="blank">
            Política de Privacidade
          </a>`,
        },
      },
    },
    fr: {
      translation: {
        widget: {
          title: "Acheter et revendre n'a jamais été aussi facile!",
          description: `Resellify est la plateforme idéale pour revendre vos articles de luxe. Ajoutez automatiquement un nouveau produit que vous achetez à votre garde-robe numérique et choisissez de le revendre quand vous voulez d'un simple clic! Facile, rapide et responsable.`,
          cta: "En savoir plus",
          disclaimer: `Vous êtes sur le point de partager vos données personnelles et vos achats avec la plateforme de marché Resellify. Pour plus de détails, voir notre`,
          terms: "J'accepte",
        },
        modal: {
          title: "Comment ça marche",
          step1: `<p><strong>1. Ajouter à Resellify</strong></p> Nous nous sommes associés à Resellify pour vous permettre de rejoindre le mouvement de la mode circulaire. Revendez vos achats sur la plateforme Resellify en un simple clic. Ensemble, nous pouvons faire notre part pour garder la mode hors des décharges.`,
          step2: `<p><strong>2. Revendre après</strong></p>
          Après avoir porté et aimé votre article, connectez-vous à app.resellify.it pour répertorier votre article sur le marché Resellify en un seul clic.`,
          step3: `<p><strong>💡 Que se passe-t-il si je n'ai pas de compte Resellify?</strong></p>
          Pas de soucis! Vous serez dirigé pour configurer votre compte gratuit sur la page de confirmation de commande.`,
          step4: ` Vous êtes sur le point de partager vos données personnelles et vos achats avec la plateforme de marché Resellify. Pour plus de détails, voir notre &nbsp;
          <a href="https://app.resellify.it/privacy-policy" target="blank">
            Politique de confidentialité
          </a>`,
        },
      },
    },
    it: {
      translation: {
        widget: {
          title: "Comprare e Ri-vendere non è mai stato cosi facile!",
          description: `Resellify è la piattaforma perfetta per rivendere i Tuoi articoli di
          lusso. Aggiungi automaticamente un prodotto nuovo che stai
          acquistando al tuo Guardaroba digitale e scegli di rivenderlo quando
          vuoi con un semplice click! Facile, veloce e responsabile.`,
          cta: "Ulteriori informazioni",
          disclaimer: `Stai per condividere i tuoi dati personali e i tuoi acquisti con la
          piattaforma marketplace Resellify. Per maggiori dettagli vedi la
          nostra`,
          terms: "Acconsento",
        },
        modal: {
          title: "Come funziona",
          step1: `<p><strong>1. Aggiungi a Resellify</strong></p> Abbiamo stretto una partnership con Resellify
          per consentirti di aderire al movimento circolare della moda. Rivendi
          i tuoi acquisti sulla piattaforma Resellify con un semplice clic.
          Insieme, possiamo fare la nostra parte per tenere la moda fuori dalle
          discariche.`,
          step2: `<p><strong>2. Rivendi Dopo</strong></p>
          Dopo aver indossato e donato amore al tuo articolo, accedi all' app.resellify.it per mettere in vendita il tuo articolo sul marketplace di Resellify con un solo click.`,
          step3: `<p><strong>💡 Cosa succede se non ho un account Resellify?</strong></p>
          Niente panico! Verrai indirizzato a configurare il tuo account gratuito sulla pagina di conferma dell'ordine.`,
          step4: ` Stai per condividere i tuoi dati personali e i
          tuoi acquisti con la piattaforma marketplace Resellify. Per maggiori
          dettagli vedi la nostra &nbsp;
          <a href="https://app.resellify.it/privacy-policy" target="blank">
            Privacy Policy
          </a>`,
        },
      },
    },
  },
});

const widgetDivs = document.querySelectorAll(".gp-widget");

widgetDivs.forEach((widgetDiv) => {
  const root = ReactDOM.createRoot(widgetDiv);
  root.render(
    <React.StrictMode>
      <I18nextProvider i18n={i18next}>
        <ApolloProvider client={client}>
          <App dataset={widgetDiv.dataset} />
        </ApolloProvider>
      </I18nextProvider>
    </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
