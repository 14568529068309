// Assumendo che `locale` venga passato come proprietà o gestito nello stato globale
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
const token = "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";

const autocompleteMockData = {
  products: [
    // (mock data non modificato)
  ],
};

export default function SkuSearch() {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [autocompleteData, setAutocompleteData] = useState();
  const [suggestions, setSuggestions] = useState([]);
  const [locale, setLocale] = useState("it");

  useEffect(() => {
    const _loc = window?.Shopify?.locale;
    if (_loc) {
      setLocale(_loc);
    }
  }, []);

  const onSubmit = (data) => {
    console.log(data);
    if(locale === "it") {
    window.location.href = `/search?q=${data.search}`;
    }
    else{
      window.location.href = `/en/search?q=${data.search}`;
    }
  };

  const autocomplete = async (q) => {
    const response = await axios.get(`https://gp.bkt.studio/shopify/products`, {
      headers: {
        "X-Shopify-Access-Token": token,
        "Content-Type": "application/json",
      },
    }).catch((err) => {});
    try {
      setAutocompleteData(response.data.products);
    } catch (err) {
      setAutocompleteData(autocompleteMockData.products);
    }
  };

  const performSearch = (q) => {
    const _suggestions = [];
    autocompleteData?.filter(p => p.status === 'active').forEach((product) => {
      product.variants.forEach((variant) => {
        if (variant.sku?.toLowerCase().includes(q.toLowerCase())) {
          _suggestions.push(product);
        }
      });
    });

    setSuggestions(_suggestions);
  };

  useEffect(() => {
    const _q = watch("search");

    if (_q?.length > 2) {
      if (!autocompleteData) {
        autocomplete(_q);
      } else {
        performSearch(_q);
      }
    } else {
      setSuggestions([]);
    }
  }, [watch("search")]);

  useEffect(() => {
    if (autocompleteData) {
      performSearch(watch("search"));
    }
  }, [autocompleteData]);



  return (
    <div>
      <form
        role="search"
        method="get"
        className="searchform"
        onSubmit={handleSubmit(onSubmit)}
      >
        <fieldset>
          <input
            type="search"
            className="search-field"
            placeholder={
              locale === "it" ? "Cerca per Codice Prodotto" : "Search by Product Code"
            }
            {...register("search", { required: true })}
            autoComplete="off"
            style={{
              background: "rgba(0,0,0,.7)",
              borderRadius: 0,
            }}
          />
          {errors.search && (
            <span>
              {locale === "it" ? "Questo campo è obbligatorio" : "This field is required"}
            </span>
          )}

          <button type="submit" className="thb-search-submit">
            {/* Icon SVG rimane invariato */}
          </button>
        </fieldset>
      </form>
      {suggestions.length > 0 && (
        <div
          style={{
            background: "rgba(0,0,0,.7)",
          }}
        >
          {suggestions.map((product) => {
            return product.variants.map((variant) => {
              if (!variant.sku) {
                return null;
              }
              return (
                <button
                  onClick={() => {
                    if(locale === "it") {
                      window.location.href = `/products/${product.handle}?variant=${variant.id}`;
                    }else{
                      window.location.href = `/en/products/${product.handle}?variant=${variant.id}`;
                    }
                  }}
                  key={variant.id}
                  style={{
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      padding: "5px 10px",
                      borderBottom: "none",
                      width: "100%",
                      justifyContent: "space-around",
                    }}
                  >
                    {product?.image && (
                      <img
                        src={product.image.src}
                        alt={product.title}
                        style={{ width: "100px", marginRight: "10px" }}
                      />
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <p style={{ marginBottom: 0 }}>{product.title}</p>
                      <p style={{ marginBottom: 0, opacity: 0.8 }}>
                        <small>{variant.sku}</small>
                      </p>
                    </div>
                    <div>
                      <p style={{ marginBottom: 0, textAlign: "right" }}>
                        €{variant.price}
                      </p>
                    </div>
                  </div>
                </button>
              );
            });
          })}
        </div>
      )}
    </div>
  );
}
