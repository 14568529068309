import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import Loading from "./Loading";
const token = "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";

const BrandSelect = ({ cb, queryObject, setQueryObject }) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [locale, setLocale] = useState("it");

  useEffect(() => {
    const _loc = window?.Shopify?.locale;
    if (_loc) {
      setLocale(_loc);
    }
  }
  , []);

  const retrieveData = async () => {
    setLoading(true);
    const response = await axios
      .get("https://gp.bkt.studio/entity")
      .catch((e) => console.log(e));
    setData(response.data);
    setLoading(false);
  };

  useEffect(() => {
    retrieveData();
  }, []);

  return (
    <select
      style={{
        flex: 1,
        maxWidth: "100px",
      }}
      onChange={(e) => {
        console.log("value", e.target.value);
        cb(e.target.value);
        setQueryObject({
          ...queryObject,
          casa: e.target.value,
        });
      }}
    >
      <option selected disabled>
        
        {locale === "it" ? "Produttore" : "Brand"}
      </option>
      {[...new Set(data?.map(d => d.casa))]?.sort(
        (a,b) => a.localeCompare(b)
      ).map((brand,id) => (
        <option key={"casa-"+id} value={brand}>
          {brand}
        </option>
      ))}
    </select>
  );
};

const ModelliSelect = ({ cb, dependency, queryObject, setQueryObject }) => {
  const [data, setData] = useState();

  const [loading, setLoading] = useState(false);
  const [locale, setLocale] = useState("it");

  useEffect(() => {
    const _loc = window?.Shopify?.locale;
    if (_loc) {
      setLocale(_loc);
    }
  }
  , []);

  const retrieveData = async () => {
    setLoading(true);
    const response = await axios
      .post("https://gp.bkt.studio/entity/find",{
        casa: queryObject.casa
      })
      .catch((e) => console.log(e));
    setData(response.data);
    setLoading(false);
  };
  useEffect(() => {
    if (dependency) {
      cb(null);
      retrieveData();
    }
  }, [dependency]);
  if (loading)
    return (
      <div
        style={{
          flex: 1,
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        {" "}
        <Loading />
      </div>
    );

  return (
    <select
    disabled={!dependency}
    style={{
      flex: 1,
    }}
    onChange={(e) => {
      console.log("value", e.target.value);
      cb(e.target.value);
      setQueryObject({
        ...queryObject,
        casa: dependency,
        modello: e.target.value,
      });
    }}
  >
    <option selected disabled>
      {locale === "it" ? "Modello" : "Model"}
    </option>
    {[...new Set(data?.map(d => d.modello))]?.sort(
      (a,b) => a.localeCompare(b)
    ).map((brand,id) => (
      <option key={"modello-"+id} value={brand}>
        {brand}
      </option>
    ))}
  </select>
  );
};

const PorteOption = ({ porte }) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const retrieveData = async () => {
    setLoading(true);
    const response = await axios
      .get("https://gp.bkt.studio/year?portsId=" + porte.metaId)
      .catch((e) => console.log(e));
    setData(response.data);
    setLoading(false);
  };

  useEffect(() => {
    retrieveData();
  }, []);

  return (
    <option key={porte._id} value={porte.metaId}>
      {porte.name}p ({data && data?.length > 0 && data[0]?.name})
    </option>
  );
};

const PorteSelect = ({ cb, dependency, queryObject, setQueryObject }) => {
  const [data, setData] = useState();
  const [locale, setLocale] = useState("it");

  useEffect(() => {
    const _loc = window?.Shopify?.locale;
    if (_loc) {
      setLocale(_loc);
    }
  }
  , []);

  const [loading, setLoading] = useState(false);

  const retrieveData = async () => {
    setLoading(true);
    const response = await axios
      .post("https://gp.bkt.studio/entity/find",{
        casa: queryObject.casa,
        modello: queryObject.modello
      })
      .catch((e) => console.log(e));
    setData(response.data);
    setLoading(false);
  };

  useEffect(() => {
    if (dependency) {
      cb(null);
      retrieveData();
    }
  }, [dependency]);
  if (loading)
    return (
      <div
        style={{
          flex: 1,
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        {" "}
        <Loading />
      </div>
    );
  return (
    <select
    disabled={!dependency}
    style={{
      flex: 1,
    }}
    onChange={(e) => {
      console.log("value", e.target.value);
      cb(e.target.value);
      setQueryObject({
        ...queryObject,
        casa: queryObject.casa,
        modello: queryObject.modello,
        porte: e.target.value,
      });
    }}
  >
    <option selected disabled>
      {locale === "it" ? "Porte" : "Doors"}
    </option>
    {[...new Set(data?.map(d => d.porte))]?.sort(
      (a,b) => a.localeCompare(b)
    ).map((brand,id) => (
      <option key={"porte-"+id} value={brand}>
        {brand}
      </option>
    ))}
  </select>
  );
};

const AnnoSelect = ({ cb, dependency, queryObject, setQueryObject }) => {
  const [data, setData] = useState();
  const [value, setValue] = useState();

  const [loading, setLoading] = useState(false);
  const [locale, setLocale] = useState("it");

  useEffect(() => {
    const _loc = window?.Shopify?.locale;
    if (_loc) {
      setLocale(_loc);
    }
  }
  , []);

  const retrieveData = async () => {
    setLoading(true);
    const response = await axios
      .post("https://gp.bkt.studio/entity/find",{
        casa: queryObject.casa,
        modello: queryObject.modello,
        porte: queryObject.porte
      })
      .catch((e) => console.log(e));
    setData(response.data);
    setLoading(false);
  };

  useEffect(() => {
    if (data) {
      if (data.length === 1) {
        cb(data[0].metaId);
        setValue(data[0].metaId);
        setQueryObject({
          ...queryObject,
          anno: data?.find((y) => y.metaId === data[0].metaId)?.name,
        });
      }
    }
  }, [data]);

  useEffect(() => {
    if (dependency) {
      cb(null);
      retrieveData();
    }
  }, [dependency]);

  if (loading)
    return (
      <div
        style={{
          flex: 1,
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        {" "}
        <Loading />
      </div>
    );
  return (
    <select
    disabled={!dependency}
    style={{
      flex: 1,
    }}
    onChange={(e) => {
      console.log("value", e.target.value);
      cb(e.target.value);
      setQueryObject({
        ...queryObject,
        casa: queryObject.casa,
        modello: queryObject.modello,
        porte: queryObject.porte,
        anno: e.target.value,
      });
    }}
  >
    <option selected disabled>
      {locale === "it" ? "Anno" : "Year"}
    </option>
    {[...new Set(data?.map(d => d.anno))]?.sort(
      (a,b) => a.localeCompare(b)
    ).map((brand,id) => (
      <option key={"anno-"+id} value={brand}>
        {brand}
      </option>
    ))}
  </select>
  );
};

export default function Advanced() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    console.log('queryObject', queryObject)
    const response = await axios
      .post("https://gp.bkt.studio/entity/find", queryObject)
      .catch((e) => console.log(e));

    console.log(response.data);
    const result = response.data[0]?.slug;
    if (result) {
      if(locale === "it") {
        window.location.href = `/collections/${result}`;
      }else{
        window.location.href = `/en/collections/${result}`;
      }
    }
  };

  const [locale, setLocale] = useState("it");

  useEffect(() => {
    const _loc = window?.Shopify?.locale;
    if (_loc) {
      setLocale(_loc);
    }
  }
  , []);

  const [brand, setBrand] = useState();
  const [modelli, setModelli] = useState();
  const [porte, setPorte] = useState();
  const [anno, setAnno] = useState();
  const [queryObject, setQueryObject] = useState({});

  return (
    <div>
      <form
        role="search"
        method="get"
        className="searchform"
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <fieldset
          className="fs-advanced-search"
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <BrandSelect
            cb={setBrand}
            queryObject={queryObject}
            setQueryObject={setQueryObject}
          />
          <ModelliSelect
            dependency={brand}
            cb={setModelli}
            queryObject={queryObject}
            setQueryObject={setQueryObject}
          />
          <PorteSelect
            dependency={modelli}
            cb={setPorte}
            queryObject={queryObject}
            setQueryObject={setQueryObject}
          />
          
          
          <AnnoSelect
            dependency={porte}
            cb={setAnno}
            queryObject={queryObject}
            setQueryObject={setQueryObject}
          />

        </fieldset>
        <input
          type="submit"
          style={{
            flex: 1,
          }}
          disabled={!anno}
          value={locale === "it" ? "Cerca" : "Search"}
        />
      </form>
    </div>
  );
}
